import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';

@Injectable()
export abstract class AuthenticationService
{
    public _authenticated: boolean = false;
    protected baseUrl: string;
    public authProviderName: string;

    /**
     * Constructor
     */
    constructor(
        protected _httpClient: HttpClient,
        protected _userService: UserService
    )
    {
        this.baseUrl = `${environment.apiURL}/auth`;
        
        this.authProviderName = `${environment.authProvider}`;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
  
    abstract hasOwnInterceptor(): boolean;

    /**
    /**
     * Forgot password
     *
     * @param email
     */
    abstract forgotPassword(resetEmail: {email: string}): Observable<any>;

    /**
     * Reset password
     *
     * @param password
     */
    abstract resetPassword(credentials: { email: string; password: string; password_confirmation: string; token: string }): Observable<any>;

    /**
     * Sign in
     *
     * @param credentials
     */
    abstract signIn(credentials: { email: string; password: string }): Observable<any>;

    /**
     * Sign in using the access token
     */
    abstract signInUsingToken(signInUsingTokenService: any|null): Observable<any>;

    /**
     * Sign out
     */
    abstract signOut(signOutService?: any|null): Observable<any>;

    /**
     * Should allow Sign up
     *
     */
    abstract shouldAllowSignUp(): boolean;

    /**
     * Sign up
     *
     * @param user
     */
    abstract signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>;

    /**
     * Unlock session
     *
     * @param credentials
     */
    abstract unlockSession(credentials: { email: string; password: string }): Observable<any>;

    /**
     * Check the authentication status
     */
    abstract check(checkService?: any|null): Observable<boolean>;
}