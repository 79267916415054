<!-- <div class="flex flex-row justify-between items-center">
    <div class="gap-2 flex flex-row">
        <mat-icon svgIcon="mat_solid:visibility" class="icon-small"></mat-icon>
        <h2 class="font-bold">Ver grupos</h2>
    </div>

    <button (click)="close()" class="mt-1">
        <mat-icon svgIcon="mat_solid:close" class="icon-small"></mat-icon>
    </button>
</div>
<hr style="margin: 5px 0px"> -->

<div class="flex flex-col mt-5">
    <div class="w-full">
        <div class="w-full flex gap-2">
            <div class="w-1/2">
                <img src="../../../../../../assets/images/pages/notification/anniversary.png"
                    class="object-contain h-[200px]" alt="">
            </div>
            <div class="w-1/2 flex items-center text-center text-primary text-bold text-3xl mb-3">
                {{anios}}
            </div>
        </div>
    </div>
    <div class="flex flex-col gap-3 mt-4 overflow-y-auto">
        <!-- <div class="text-center font-bold">
            Bienvenido a Plugthem
        </div> -->
        <div>
            <span class="text-justify">
                {{mensaje}}
            </span>
        </div>
        <div class="text-center">
            <button mat-flat-button color="primary" class="rounded-xl" (click)="close()"
                type="submit">¡Gracias!</button>
        </div>
    </div>
</div>