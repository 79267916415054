import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthenticationService } from 'app/core/auth/auth.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardSsoKc extends KeycloakAuthGuard implements CanActivate, CanActivateChild, CanLoad
{
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthenticationService,
        private _router: Router,
        protected keycloak: KeycloakService
    )
    {
        super(_router, keycloak);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this.canActivate(childRoute, state);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
    {
        return this._check();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @private
     */
    public _check(): Observable<boolean>
    {
        // Check the authentication status
        return this._authService.check(this.keycloak)
                   .pipe(
                       switchMap((authenticated) => {

                           // If the user is not authenticated...
                           if ( !authenticated )
                           {
                               // Redirect to the root
                               this._router.navigate(['']);

                               // Prevent the access
                               return of(false);
                           }

                           // Allow the access
                           return of(true);
                       })
                   );
    }

    public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            this._authService._authenticated = false; // unauthenticated in auth service
            await this.keycloak.login({
                redirectUri: window.location.origin + state.url
            });
        }

        this._authService._authenticated = true; // authenticate in auth service
        this._authService.accessToken = (await this.keycloak.getToken()).valueOf(); // store bearer token in auth service
    
        // Get the roles required from the route.
        const requiredRoles = route.data.roles;
    
        // Allow the user to proceed if no additional roles are required to access the route.
        if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
          return true;
        }
    
        // Allow the user to proceed if all the required roles are present.
        return requiredRoles.every((role) => this.roles.includes(role));
    }
}