<!-- <div class="flex flex-row justify-between items-center">
    <div class="gap-2 flex flex-row">
        <mat-icon svgIcon="mat_solid:visibility" class="icon-small"></mat-icon>
        <h2 class="font-bold">Ver grupos</h2>
    </div>

    <button (click)="close()" class="mt-1">
        <mat-icon svgIcon="mat_solid:close" class="icon-small"></mat-icon>
    </button>
</div>
<hr style="margin: 5px 0px"> -->

<div class="flex flex-col sm:h-[250px] h-[165px] w-full imagen_background bg-primary justify-center items-center">
    <div class="flex w-full text-center justify-center items-center">
        <div class="sm:flex hidden w-[20%] justify-end items-center h-full">
            <img src="../../../../../../assets/images/pages/notification/welcome-girl-1.png" class="object-contain" style="height: 70%; width: 70%;" alt="">
        </div>
        <div class="flex flex-col sm:w-[60%] w-full justify-center items-center">
            <div class="text-center font-bold text-white text-3xl sm:p-3 sm:mb-3">
                {{ title }}
            </div>
            <div class="max-w-[100px] min-w-[100px] min-h-[100px] max-h-[100px] rounded-full bg-white sm:flex items-center justify-center hidden">
                <img [src]="img" class="object-contain" style="height: 70%; width: 70%;" alt="">
            </div>
        </div>
        <div class="sm:flex hidden w-[20%] justify-start items-center h-full">
            <img src="../../../../../../assets/images/pages/notification/welcome-girl-2.png" class="object-contain" style="height: 70%; width: 70%;" alt="">
        </div>
    </div>
</div>

<div class="flex flex-col mt-5 mb-5">
    <div class="flex flex-col gap-3 mt-4 overflow-y-auto text-center">
        <span class="text-center w-full break-words px-3 pb-3">
            {{ mensaje }}
            <!-- esta es una palabra que tiene 450 caracteres esta es una palabra que tiene 450 caracteres esta es una palabra que tiene 450 caracteres esta es una palabra que tiene 450 caracteres esta es una palabra que tiene 450 caracteres esta es una palabra que tiene 450 caracteres esta es una palabra que tiene 450 caracteresesta es una palabra que tiene 450 caracteresesta es una palabra que tiene 450 caracteresesta es una palabra que tiene 450 caracteres sdw -->
        </span>

        <div class="flex w-full justify-center">
            <div class="flex w-[145px] h-[40px] text-center rounded-xl justify-center items-center bg-primary cursor-pointer" (click)="close()">
                <span class="text-white">
                    Empezar a usar
                </span>
            </div>
        </div>
    </div>
</div>
