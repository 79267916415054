import { HttpClient } from '@angular/common/http';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { AuthServiceSsoKc } from 'app/core/auth/auth.service.ssokc';
import { AuthServiceDefault } from 'app/core/auth/auth.service.default';
import { KeycloakOptions, KeycloakService } from 'keycloak-angular';

export function AuthenticationFactory(_httpClient: HttpClient, _userService: UserService)
{
    if (`${environment.authProvider}` === 'ssokc') {
       return new AuthServiceSsoKc(_httpClient, _userService);
    }

    return new AuthServiceDefault(_httpClient, _userService);
}

export function ShouldInitializeKeycloak(keycloak: KeycloakService)
{
    // Initialize Keycloak when auth provider is ssokc
    if (`${environment.authProvider}` === 'ssokc')
    {
        //console.log("Initializing SSO");
        const options: KeycloakOptions = {
            config : environment.ssokc,
            initOptions: {
                onLoad: 'check-sso',
                // onLoad: 'login-required',
                //checkLoginIframe: false,
                silentCheckSsoRedirectUri: window.location.origin + '/assets/auth/silent-check-sso.html'
            },
            enableBearerInterceptor: true, // Intercepts http requests to add token in header
            authorizationHeaderName: 'Authorization', // Token header name
            bearerPrefix: 'Bearer', // Token header prefix
            bearerExcludedUrls: ['/assets'], // to exclude the urls that should not have the Authorization Header automatically
            loadUserProfileAtStartUp: false,  // If user profile should be loaded at the keycloak initialization
            updateMinValidity: 20, // Determines if the token will be refreshed based on its expiration time.
            shouldAddToken: () => true, // if the request should have the token added to the headers by the KeycloakBearerInterceptor
            shouldUpdateToken: () => true // if the request being made should potentially update the token.
        };

        return () => keycloak.init(options);
    }

    // Dummy factory function
    // Fix for "this.appInits[i] is not a function".
    return () => {
        return undefined;
    };
}