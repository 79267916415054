import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-crumbs',
  templateUrl: './crumbs.component.html',
  styleUrls: ['./crumbs.component.scss']
})
export class CrumbsComponent implements OnInit {

    @Input() origin: string;
    @Input() destin: string;
    @Input() url: string;

    private id: string;

    constructor(
        private router: Router
    ) {
        this.origin = '';
        this.destin = '';
        this.id = '0';
    }

    ngOnInit(): void {

    }

    redirect(){
        this.router.navigateByUrl(this.url);
    }
}
