import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AuthenticationService } from 'app/core/auth/auth.service';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';

@Injectable()
export class AuthServiceDefault extends AuthenticationService
{
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods for authentication with app backend
    // -----------------------------------------------------------------------------------------------------

    /**
     * Auth service has own http interceptor
     */
    hasOwnInterceptor(): boolean
    {
        return false;
    }

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(resetEmail: {email: string}): Observable<any>
    {
        return this._httpClient.post(this.baseUrl + '/forgot-password', resetEmail);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(credentials: { email: string; password: string; password_confirmation: string; token: string }): Observable<any>
    {
        return this._httpClient.post(this.baseUrl + '/reset-password', credentials);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(this.baseUrl + '/sign-in', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Renew token
        return this._httpClient.post(this.baseUrl + '/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                if(response.accessToken) {
                    // Store the access token in the local storage
                    this.accessToken = response.accessToken;

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Return true
                    return of(true);
                } else {

                    // Remove the access token from the local storage
                    localStorage.removeItem('accessToken');

                    // Set the authenticated flag to false
                    this._authenticated = false;

                    // Return false
                    return of(false)
                }
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Revoke token if authenticated
        if(this._authenticated){
            const req = this._httpClient.post(this.baseUrl + '/sign-out', {
                accessToken: this.accessToken
            });
            req.subscribe(); // post http observable is lazy, need to subscribe to execute the request
        }

        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }


    /**
     * Should allow Sign up
     */
    shouldAllowSignUp(): boolean
    {
        return true; // Return the observable
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post(this.baseUrl + '/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post(this.baseUrl + '/unlock-session', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}