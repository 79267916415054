import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AuthenticationService } from 'app/core/auth/auth.service';
import { Observable, of } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class AuthServiceSsoKc extends AuthenticationService
{
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods to manage authentication with SSO (Keycloak)
    // -----------------------------------------------------------------------------------------------------

    /**
     * Auth service has own http interceptor
     */
    hasOwnInterceptor(): boolean
    {
        return true;
    }

    /**
     * Forgot password, not available with SSO
     *
     * @param email
     */
    forgotPassword(resetEmail: {email: string}): Observable<any>
    {
        return of(false); // Return the observable
    }

    /**
     * Reset password, not available with SSO
     *
     * @param password
     */
    resetPassword(credentials: { email: string; password: string; password_confirmation: string; token: string }): Observable<any>
    {
        return of(false); // Return the observable
    }

    /**
     * Sign in, not available with SSO
     * Login is don with SSO redirect
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        return of(false);
    }

    /**
     * Sign out
     */
    signOut(keycloak: KeycloakService): Observable<any>
    {
        // Remove the access token from the local storage
        //localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Redirect to logout the user.
        let redirectURL = window.location.origin;
        keycloak.logout(redirectURL);

        // Return the observable
        return of(true);
    }


    /**
     * Should allow Sign up
     */
    shouldAllowSignUp(): boolean
    {
        return false; // Return the observable
    }

    /**
     * Sign up, not available with SSO
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return of(false);
    }

    /**
     * Unlock session, not available with SSO
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return of(false);
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(keycloak: KeycloakService): Observable<any>
    {
        // Renew token
        keycloak.updateToken(20)
            .then(() =>{
                this._authenticated = true; // authenticate in auth service
                this.accessToken = (keycloak.getToken()).toString(); // store bearer token in auth service

                // Return true
                return of(true);
            })
            .catch(error => {
                console.error(error);
                // Return false
                return of(false);
            });

        return of(false);
    }

    /**
     * Check the authentication status
     */
    check(keycloak: KeycloakService): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken(keycloak);
    }
}
