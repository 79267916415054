import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-anniversary',
  templateUrl: './anniversary.component.html',
  styleUrls: ['./anniversary.component.scss']
})
export class AnniversaryComponent implements OnInit {
  public notification: any;
  public mensaje: string = '';
  public anios: string = '';

  constructor(
    public dialogRef: MatDialogRef<AnniversaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.notification = this.data;
    this.mensaje = this.notification.content.message;
    this.anios = '¿Ya pasaron ' + this.notification.content.years + ' años?'
  }

  close(): void {
    this.dialogRef.close();
  }

}
