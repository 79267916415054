import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.scss']
})
export class RewardComponent implements OnInit {

  public notification: any;
  public mensaje: string = '';
  public nuevomensaje:any;
  public mensaje2:any;
  constructor(
    public dialogRef: MatDialogRef<RewardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    
  ) 
  { 
  }

  ngOnInit(): void {
    this.notification = this.data;
    this.mensaje = this.notification.content.message; 
    this.nuevomensaje=this.mensaje.split(',');
    this.mensaje=this.nuevomensaje[1].replace('Plug','puntos');
    this.mensaje=this.mensaje.replace('Coins','');
  }

  close(): void {
    this.dialogRef.close();
  }

}
