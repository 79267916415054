import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-happy-birthday',
  templateUrl: './happy-birthday.component.html',
  styleUrls: ['./happy-birthday.component.scss']
})
export class HappyBirthdayComponent implements OnInit {

  public notification: any;
  public mensaje: string = '';
  
  constructor
  (
    public dialogRef: MatDialogRef<HappyBirthdayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) 
  { 

  }

  ngOnInit(): void {
    this.notification = this.data;
    // console.log(this.notification);
    this.mensaje = this.notification.content.message;
    // console.log(this.mensaje);
  }

  close(): void {
    this.dialogRef.close();
  }

}
