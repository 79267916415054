import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-home',
  templateUrl: './progress-home.component.html',
  styleUrls: ['./progress-home.component.scss']
})
export class ProgressHomeComponent implements OnInit {
  
  @Input() progress = 0;
  constructor() { }

  ngOnInit(): void {
  }

}
