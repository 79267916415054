import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'apps/home'},
    {path: 'apps', pathMatch : 'full', redirectTo: 'apps/home'},

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'apps/home'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        resolve: {
        //   initialData: InitialDataResolver,
        },
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'api/auth/reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},
            {path:'api/auth/email/verify',loadChildren:()=>import('app/modules/auth/email-verify/email-verify.module').then(m=>m.EmailVerifyModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },

    // Admin routes
    {
        path       : '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [

            // Apps
            {path: 'apps', children: [
                {path: 'softphone', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)},
                {path: 'recordings', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)},
                {
                    path: 'settings',
                    loadChildren: () => import('app/modules/admin/setting/setting.module').then( m => m.SettingModule )
                },
                {path: 'users', loadChildren: () => import('app/modules/admin/users/users.module').then(m => m.UsersModule)},
                {path: 'groups', loadChildren: () => import('app/modules/admin/groups/groups.module').then(m => m.GroupsModule)},
                {path: 'roles', loadChildren: () => import('app/modules/admin/roles/roles.module').then(m => m.RolesModule)},
                {
                    path: 'inventory',
                    loadChildren: () => import('app/modules/admin/inventary/inventary.module').then( m => m.InventaryModule )
                },
                {path: 'levels', loadChildren: () => import('app/modules/admin/levels/levels.module').then(m => m.LevelsModule)},
                {
                    path: 'marketplace',
                    loadChildren: () => import('app/modules/normal/marketplace/marketplace.module').then( m => m.MarketplaceModule )
                },

                {path: 'home', loadChildren: () => import('app/modules/general/home/home.module').then(m => m.HomeModule)},
                {path: 'first-login', loadChildren: () => import('app/modules/general/first-login/first-login.module').then(m => m.FirstLoginModule)},
                {path: 'points-management', loadChildren: () => import('app/modules/admin/points-management/points-management.module').then(m => m.PointsManagementModule)},
                {path: 'deliveries', loadChildren: () => import('app/modules/admin/deliveries/deliveries.module').then(m => m.DeliveriesModule)},
                {path: 'missions', loadChildren: () => import('app/modules/normal/missions/missions.module').then(m => m.MissionsModule)},
                {
                    path: 'mission-management',
                    loadChildren: () => import('app/modules/admin/mission-management/mission-management.module').then( m => m.MissionManagementModule )
                },

                {path: 'trivias-management', loadChildren: () => import('app/modules/admin/trivias-management/trivias-management-module').then(m => m.TriviasModule)},
                {path: 'usage-report', loadChildren: () => import('app/modules/admin/reports/usage-report/usage-report.module').then(m => m.UsageReport)},
                {path: 'inventory-report', loadChildren: () => import('app/modules/admin/reports/inventory-report/inventory-report.module').then(m => m.InventoryReport)},

            ]},

            {path: 'user-profile', loadChildren: () => import('app/layout/common/user/user-profile/user-profile.module').then(m => m.UserProfileModule)}
        ]
    }
];
