import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { SettingService } from 'app/modules/admin/setting/service/setting.service';
import { FuseConfigService } from '@fuse/services/config';
import { Setting } from 'app/modules/admin/setting/service/setting.type';

@Component({
    selector: 'thin-layout',
    templateUrl: './thin.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ThinLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public url: string = '';

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private settingService: SettingService,
        private changeDetectorRef: ChangeDetectorRef,
        private fuseConfigService: FuseConfigService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        //    this.settingService.setting$
        //    .pipe(takeUntil(this._unsubscribeAll))
        //    .subscribe(res => {
        //       if(res) {
        //         this.url = res.url_logo;
        //       }else {
        //         this.url = 'assets/images/logo/logo.svg';
        //       }
        //    });
        this.settingService.getSettingCustomer()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res) {
                    this.url = res.logo;
                } else {
                    this.url = 'assets/images/logo/logo.svg';
                }
                //this.fuseConfigService.config = res.color
                this.changeDetectorRef.detectChanges();
            });
    }

    ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.settingService.getSettingCustomer()
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(
        //         {
        //             next: (data: Setting) => {

        //             }

        //         }
        //     )
        //     this.settings$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe({
        //         next:(data:GlobalSettings[])=>{
        //             let isologo:GlobalSettings = data.find(item=>item.url_logo!=null);
        //             if(isologo!=null){
        //                 this.logo = isologo.url_logo;
        //             }
        //         }
        //     });
        // }, 0);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
