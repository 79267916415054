/**
 * Here you can add the configuration related to keycloak SSO
 * So we can use this common config for different environment
 */
import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'https://sso.voc.cx', // Keycloak base URL  
  realm: 'bgr_oidc1', // Keycloak realm id  
  clientId: 'rewards-bgr-stage', // keycloak client id
};

export default keycloakConfig;