import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';

interface PercentCircle {
    percent: number;
    value: number,
    percentInv: number,
    valueInv: number
}

export interface OptionDonut {
    text?: string;
    cssText?: string;
}

@Component({
    selector: 'app-donut',
    templateUrl: './donut.component.html',
    styleUrls: ['./donut.component.scss']
})
export class DonutComponent implements OnInit, AfterViewInit {

    @Input() min: number = 0;
    @Input() max: number = 10;
    @Input() progress: number = 45;
    @Input() options: OptionDonut;

    public point: number = 0;
    public percentProgress!: PercentCircle;
    public changeProgres: boolean = false;

    private maxDonut: number = 155;     //100%
    private minDonut: number = 472;     //0%

    constructor(
        private cdRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {

        this.point = this.min;
        this.initDataAndAnimation();
        this.animatePoints();
    }

    ngAfterViewInit(): void {
    }

    animatePoints(): void {
        const interval = setInterval( () => {
            if(this.progress === this.point){
                this.changeProgres = true;
                clearInterval(interval);
                this.cdRef.detectChanges();
            }else {
                this.point++;
            }
        }, 20);
    }

    initDataAndAnimation(): void {
        this.percentProgress = { percent: 0, value: 0, percentInv: 0, valueInv: 0 };
        this.percentProgress = this.calculateProgress();
        this.generateAnimationKeyframe(this.percentProgress.percent);
        this.cdRef.detectChanges();
    }

    calculateProgress(): PercentCircle {

        //Calcular la diferencia entre el máximo y el mínimo
        const dif1: number = this.max - this.min;

        //Calcular diferencia entre el progreso y el valor mínimo
        const dif2: number = this.progress - this.min;

        //Obtener la division y multiplicarlo por 100 para obtener el porcentaje
        const result = dif2 / dif1;

        const object: PercentCircle = {
            percent: Math.round(result * 100),                  // Por ejemplo 65 %
            value: parseFloat(result.toFixed(2)),               // Por ejemplo 0.65
            percentInv: 100 - Math.round(result * 100),         // Por ejemplo 45 %
            valueInv: 1 - parseFloat(result.toFixed(2))         // Por ejemplo 0.45
        };

        this.cdRef.detectChanges();
        return object;
    }

    generateAnimationKeyframe(value: number): void {

        // Calcular el valor de progreso para la dona
        const difDonut = ((this.minDonut - this.maxDonut) * value) / 100;
        const fillDonut = this.minDonut - difDonut;

        //Seleccionar el elemento al aplicar la animacion
        const circle = document.getElementById('circle');


        //Crear la regla css, el keyframe
        const rule: string = `
        @keyframes anim {
            100% { stroke-dashoffset: ${ fillDonut }; }
        } `;

        // Creamos el elemento <style>
        const style = document.createElement("style");

        // Agregamos las reglas de estilo al elemento <style>
        style.textContent = rule;

        // Agregamos el elemento <style> al documento
        document.head.appendChild(style);

        //Agregamos la animacion al objeto
        setTimeout(() => {
            circle.style.animation = 'anim 2s linear forwards';
        }, 10);

        this.cdRef.detectChanges();
    }
}
