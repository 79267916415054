<!-- <div class="flex flex-row justify-between items-center">
    <div class="gap-2 flex flex-row">
        <mat-icon svgIcon="mat_solid:visibility" class="icon-small"></mat-icon>
        <h2 class="font-bold">Ver grupos</h2>
    </div>

    <button (click)="close()" class="mt-1">
        <mat-icon svgIcon="mat_solid:close" class="icon-small"></mat-icon>
    </button>
</div>
<hr style="margin: 5px 0px"> -->

<div class="flex flex-col mt-5">
    <div class="w-full" style="position: relative;
                    display: inline-block;
                    text-align: center;">
        <img style="position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);"
            src="https://plug-erwp-media.fra1.digitaloceanspaces.com/local/settings/Y29pbg.png"
            class="object-contain h-[130px]" alt="">

        <img src="../../../../../../assets/images/pages/notification/LEVEL_STAND.png" class="object-contain h-[200px]"
            alt="">
    </div>
    <div class="flex flex-col gap-3 mt-4 overflow-y-auto">
        <div class="text-center font-bold">
            Bienvenido al nivel ORO
        </div>
        <div>
            <span class="text-justify">
                es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de
                relleno estándar de las industrias desde el año 1500, cuando un impresor
            </span>
        </div>
        <div class="text-center">
            <button mat-flat-button color="primary" class="rounded-xl" (click)="close()" type="submit">Descubrir nuevo
                nivel</button>
        </div>
    </div>
</div>