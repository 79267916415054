import { Component  } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, interval, map, tap } from 'rxjs';
@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor (private readonly swUpdate: SwUpdate) {
        this.actualizarApp();
    }

    actualizarApp(){
        if (this.swUpdate.available){
            this.swUpdate.available.subscribe(()=>{
                if(confirm('Hay una nueva versión disponible.\n¡Actualiza ahora para disfrutar de las últimas funciones y mejoras!')){
                    window.location.reload();
                }
            });
        }
    }

}

