<!-- <div class="flex flex-row justify-between items-center">
    <div class="gap-2 flex flex-row">
        <mat-icon svgIcon="mat_solid:visibility" class="icon-small"></mat-icon>
        <h2 class="font-bold">Ver grupos</h2>
    </div>

    <button (click)="close()" class="mt-1">
        <mat-icon svgIcon="mat_solid:close" class="icon-small"></mat-icon>
    </button>
</div>
<hr style="margin: 5px 0px"> -->

<div class="flex flex-col mt-5">
    <div class="w-full text-center text-black text-bold text-xl mb-3">
        ¡Recibiste un nuevo regalo!
    </div>
    <div class="w-full">
        <img src="../../../../../../assets/images/pages/notification/Chica_con_moneda.png"
            class="object-contain h-[200px]" alt="">
    </div>
    <div class="flex flex-col gap-3 mt-4 overflow-y-auto">
        <!-- <div class="text-center font-bold">
            Bienvenido a Plugthem
        </div> -->
        <div>
            <span class="text-justify">
              <h2 class="text-center">{{mensaje}}</h2>  
            </span>
        </div>
        <div class="text-center">
            <button mat-flat-button color="primary" class="rounded-xl" (click)="close()"
                type="submit">Aceptar regalo</button>
        </div>
    </div>
</div>