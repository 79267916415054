import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CrumbsComponent } from './crumbs/crumbs.component';
import { ProgressComponent } from './progress/progress.component';
import { DonutComponent } from './donut/donut.component';
import { ProgressHomeComponent } from './progress-home/progress-home.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CrumbsComponent,
        ProgressComponent,
        DonutComponent,
        ProgressHomeComponent
    ],
    declarations: [
      CrumbsComponent,
      ProgressComponent,
      DonutComponent,
      ProgressHomeComponent
    ]
})
export class SharedModule
{
}
