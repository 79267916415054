import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingService } from 'app/modules/admin/setting/service/setting.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public img: string = ''
  public notification: any;
  public mensaje: string = '';
  public title: string = '';
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public dialogRef: MatDialogRef<WelcomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settinService: SettingService,
    private changeDetectorRef: ChangeDetectorRef,
    private settingService: SettingService
  ) { }

  ngOnInit(): void {
    this.settinService.setting$
      .subscribe(res => {
        this.img = res.logo;
        this.title = 'Bienvenido a ' + res.title;
        this.changeDetectorRef.detectChanges();
      });
    this.notification = this.data;

    let modal = document.getElementById('welcome-modal');
    if (modal) {
        modal.setAttribute("style","padding: 0px !important;")
    }

    this.mensaje = this.notification.content.message;
  }

  close(): void {
    this.dialogRef.close();
  }

}
