<div class="donut w-[120px] h-[120px] relative">
    <div class="bg-primary-200 w-[120px] h-[120px] rounded-full p-[15px] border">
        <div class="inner border flex items-center justify-center flex-col w-[88px] h-[88px] rounded-full">
            <span class="block" [ngClass]="options.cssText ? options.cssText : 'text-md' ">
              <ng-container *ngIf="!changeProgres">{{ point }}</ng-container>
              <ng-container *ngIf="changeProgres">{{ progress }}</ng-container>
            </span>
            <ng-container *ngIf="options.text">
                <small style="margin-top: -10px;">{{ options.text }}</small>
            </ng-container>
        </div>
    </div>

    <!-- Circulo de progreso -->
    <svg width="120px" height="120px" class="absolute">
        <circle id="circle" cx="60" cy="60" r="50" stroke-linecap="line"/>
    </svg>
</div>
