import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuardDefault } from './auth.guard.default';
import { AuthGuardSsoKc } from './auth.guard.ssokc';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad
{
    private _authGuard;
    /**
     * Constructor
     */
    constructor(
    private _authGuardDefault: AuthGuardDefault,
    private _authGuardSsoKc: AuthGuardSsoKc
    )
    {
        if (`${environment.authProvider}` === 'ssokc')
        {
            this._authGuard = this._authGuardSsoKc; // Using ssokc guard
        } else {
            this._authGuard = this._authGuardDefault; // Using default guard
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        return this._authGuard.canActivate(route, state);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this._authGuard.canActivate(childRoute, state);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
    {
        return this._authGuard.canLoad(route, segments); 
    }
}