import { ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { MatDialog } from '@angular/material/dialog';
import { HappyBirthdayComponent } from './components/happy-birthday/happy-birthday.component';
import { AnniversaryComponent } from './components/anniversary/anniversary.component';
import { RewardComponent } from './components/reward/reward.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { Router } from '@angular/router';
import { NavigationService } from 'app/core/navigation/navigation.service';
// import { NewLevelComponent } from './components/new-level/new-level.component';
import Echo from 'laravel-echo';
import moment from 'moment';
import { analytics } from 'app/mock-api/dashboards/analytics/data';
import { SettingService } from 'app/modules/admin/setting/service/setting.service';
import { environment } from 'environments/environment';
import { FuseConfirmationService } from '@fuse/services/confirmation';
declare var require: any;

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications'
})
export class NotificationsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    notifications: Notification[];
    unreadCount: number = 0;
    public logo: string = '';
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public avatar: string = '';

    user: User;
    echoInstance: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private dialog: MatDialog,
        private _userService: UserService,
        private router: Router,
        private _navigationService: NavigationService,
        private settinService: SettingService,
        private fuseConfirmationService: FuseConfirmationService,
    ) {
        try {
            // laravel-echo requires global Pusher instance
            (<any>window).Pusher = require("pusher-js");

            /* Registering to broadcasting events */
            this.echoInstance = new Echo({
                broadcaster: "pusher",
                key: environment.echoKey,
                wsHost: "realtime-pusher.ably.io",
                wsPort: 443,
                disableStats: true,
                encrypted: true,
                cluster: '-xdxXvby5xHz1P0zJUWvEp-oHe7KCQh1L9jR5cp0lnQ'
            });
        } catch (e) {
            // console.log(e);
            const confirmation = this.fuseConfirmationService.open({
                title  : 'Error',
                message: e,
                icon: {
                    show: true,
                    name : 'heroicons_solid:exclamation',
                    color: 'error'
                },
                actions: {
                    confirm: {
                        label: 'Aceptar',
                        color: 'primary'
                    },
                    cancel:{
                        show: false
                    }
                }
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to notification changes
        this.settinService.setting$
            .subscribe(res => {
                this.logo = res.logo;
            });
        this._notificationsService.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: Notification[]) => {

                // Load the notifications
                this.notifications = notifications;
                this.notifications = this.formatNotifications(notifications);

                // Calculate the unread count
                this._calculateUnreadCount();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    ngAfterViewInit(): void {

        // notificaciones de inventario
        this.echoInstance
        //channel to change from backend. aquí cambiar
        .channel("rewards.inventories")
        .subscribed(() => {
            // console.log("You are subscribed");
        })
        ///backend event defined on listen (event,function)
        .listen(".product.warning", (event) => {

            this._buildNotification(event);
        });

        // notificaciones de mision nueva y ganador de mision
        this.echoInstance
        .channel("rewards.missions")
        .subscribed(() => {
            // console.log("You are subscribed");
        })
        .listen(".mission.warning", (event) => {
            this._buildNotification(event);
        })
        .listen(".winner.warning", (event) => {
            this._buildNotification(event);
        });

        // notificaciones de aniversario, cumpleaños y bienvenida
        this.echoInstance
        .channel("rewards.settings")
        .subscribed(() => {
            // console.log("You are subscribed");
        })
        .listen(".anniversary.reward", (event) => {
            this._buildNotification(event);
        })
        .listen(".birthday.reward", (event) => {
            this._buildNotification(event);
        })
        .listen(".welcome.reward", (event) => {
            this._buildNotification(event);
        });


        // notificacion de regalo de puntos
        this.echoInstance
        .channel("rewards.gifts")
        .subscribed(() => {
            // console.log("You are subscribed");
        })
        .listen(".gift.warning", (event) => {
            this._buildNotification(event);
        });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    formatNotifications(notifications) {
        let formattedNotifications = [];
        //Loop through all notifications
        notifications.forEach(notif => {
            let notification = {
                id: notif.id,
                uuid: notif.uuid,
                title: "",
                event: notif.event,
                content: notif.content,
                image: "",
                //data: notif.data,
                description: "",
                // time       : event.post.created,
                time: moment(notif.time).locale('es').fromNow(),
                current_time: notif.time,
                read: notif.action == 'Read' ? true : false
            }

            switch (notif.event) {
                // chanel rewards.missions
                case 'mission.warning':
                    notification.title = "¿Listo? Hay una nueva misión disponible para ti";
                    notification.image = '../../../../../../assets/images/pages/notification/noti_mision.png'
                    break;
                case 'winner.warning':
                    notification.title = "Has completado una nueva misión. ¡Reclama tu premio!";
                    notification.image = '../../../../../../assets/images/pages/notification/noti_mision.png'
                    break;
                // chanel rewards.inventories
                case 'product.warning':
                    notification.title = "Se ha agregado un nuevo ítem en la tienda ¡Ve a ver de que se trata!";
                    notification.image = '../../../../../../assets/images/pages/notification/noti_inventario.png'
                    break;
                // chanel rewards.gifts
                case 'gift.warning':
                    notification.title = "<strong>" + notification.content.sender + "</strong> Te ha regalado puntos ¡Ve a verlo!";
                    notification.image = notification.content.avatar;
                    break;
                // chanel rewards.settings
                case 'welcome.reward':
                    notification.title = "¿Eres nuevo? Bienvenido al programa de recompensas";
                    notification.image = this.logo;
                    break;
                case 'anniversary.reward':
                    notification.title = "Hoy estamos celebrando, es tu aniversario.<br>¡Tenemos un mensaje para ti! ";
                    notification.image = this.logo;
                    break;
                case 'birthday.reward':
                    notification.title = "Hoy estamos celebrando.<br>¡Feliz cumpleaños a ti! ";
                    notification.image = this.logo;
                    break;
            }

            formattedNotifications.unshift(notification);

        });
        return formattedNotifications;
    }

    openNotification(notification): void {

        switch (notification.event) {
            // chanel rewards.missions
            case 'mission.warning':
                notification.read = true;
                this._calculateUnreadCount();
                this._changeDetectorRef.detectChanges();

                // Update the notification

                this._notificationsService.updateNotificationStatus(notification.uuid).subscribe();
                this.router.navigate(['/apps/missions']);
                this.closePanel();
                break;
            case 'winner.warning':
                notification.read = true;
                this._calculateUnreadCount();
                this._changeDetectorRef.detectChanges();

                this._notificationsService.updateNotificationStatus(notification.uuid).subscribe();
                this.router.navigate(['/apps/missions']);
                this.closePanel();
                break;
            // chanel rewards.inventories
            case 'product.warning':
                notification.read = true;
                this._calculateUnreadCount();
                this._changeDetectorRef.detectChanges();

                // Update the notification

                this._notificationsService.updateNotificationStatus(notification.uuid).subscribe();
                this.router.navigate(['/apps/marketplace']);
                this.closePanel();
                break;
            // chanel rewards.gifts
            case 'gift.warning':
                notification.read = true;
                this._calculateUnreadCount();
                this._changeDetectorRef.detectChanges();

                // Update the notification

                this._notificationsService.updateNotificationStatus(notification.uuid).subscribe();
                this.closePanel();
                this.dialog.open(RewardComponent, {
                    width: '600px',
                    hasBackdrop: true,
                    disableClose: true,
                    data: notification
                }).afterClosed()
                break;
            // chanel rewards.settings
            case 'welcome.reward':
                if (notification.read != true) {
                    notification.read = true;
                    this._calculateUnreadCount();
                    this._changeDetectorRef.detectChanges();

                    // Update the notification

                    this._notificationsService.updateNotificationStatus(notification.uuid).subscribe();
                    this.closePanel();
                    this.dialog.open(WelcomeComponent, {
                        width: '800px',
                        id: 'welcome-modal',
                        hasBackdrop: true,
                        disableClose: false,
                        data: notification
                    }).afterClosed()
                }
                break;
            case 'anniversary.reward':
                notification.read = true;
                this._calculateUnreadCount();
                this._changeDetectorRef.detectChanges();

                // Update the notification

                this._notificationsService.updateNotificationStatus(notification.uuid).subscribe();
                this.closePanel();
                this.dialog.open(AnniversaryComponent, {
                    width: '600px',
                    hasBackdrop: true,
                    disableClose: true,
                    data: notification
                }).afterClosed()
                break;
            case 'birthday.reward':
                notification.read = true;
                this._calculateUnreadCount();
                this._changeDetectorRef.detectChanges();

                // Update the notification

                this._notificationsService.updateNotificationStatus(notification.uuid).subscribe();
                this.closePanel();
                this.dialog.open(HappyBirthdayComponent, {
                    width: '600px',
                    hasBackdrop: true,
                    disableClose: true,
                    data: notification
                }).afterClosed()
                break;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        // Mark all as read
        if (this.unreadCount > 0) {

            this.notifications.forEach(notif => {
                notif.read = true;
            });
            this._calculateUnreadCount();
            this._changeDetectorRef.detectChanges();
            this._userService.user$
                .subscribe({
                    next: () => {
                        this._notificationsService.updateAllNotificationStatus().subscribe();
                    }
                })

        }

    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void {
        notification.read = true;
        this._calculateUnreadCount();
        this._changeDetectorRef.detectChanges();

        // Update the notification

        this._notificationsService.updateNotificationStatus(notification.uuid).subscribe();
    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void {
        // Delete the notification
        let idx = this.notifications.indexOf(notification);
        if (idx >= 0) {
            this.notifications.splice(idx, 1);
            this._calculateUnreadCount();
            this._changeDetectorRef.detectChanges();

            // Delete the notification
            this._notificationsService.deleteNotification(notification.uuid).subscribe();
        }

    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter(notification => !notification.read).length;
        }

        this.unreadCount = count;
    }

    private _buildNotification(event) {
        let notification = {
            id: event.notification_history.id_notif,
            uuid: event.notification_history.uuid,
            title: "",
            event: event.notification_history.event_name,
            content: JSON.parse(event.notification_history.data),
            image: "",
            //data: event.data,
            description: "",
            // time       : event.post.created,
            time: moment(event.notification_history.time).locale('es').fromNow(),
            current_time: event.notification_history.time,
            read: event.action == 'Read' ? true : false
        }
        // console.log(event.notification_history.data);

        switch (event.notification_history.event_name) {
            // chanel rewards.missions
            case 'mission.warning':
                notification.title = "¿Listo? Hay una nueva misión disponible para ti";
                notification.image = '../../../../../../assets/images/pages/notification/noti_mision.png'
                break;
            case 'winner.warning':
                notification.title = "Has completado una nueva misión. ¡Reclama tu premio!";
                notification.image = '../../../../../../assets/images/pages/notification/noti_mision.png'
                break;
            // chanel rewards.inventories
            case 'product.warning':
                notification.title = "Se ha agregado un nuevo ítem en la tienda ¡Ve a ver de que se trata!";
                notification.image = '../../../../../../assets/images/pages/notification/noti_inventario.png'
                break;
            // chanel rewards.gifts
            case 'gift.warning':
                notification.title = "<strong>" + JSON.parse(event.notification_history.data).sender + "</strong> Te ha regalado puntos ¡Ve a verlo!";
                notification.image = JSON.parse(event.notification_history.data).avatar;
                break;
            // chanel rewards.settings
            case 'welcome.reward':
                notification.title = "¿Eres nuevo? Bienvenido al programa de recompensas";
                notification.image = this.logo;
                break;
            case 'anniversary.reward':
                notification.title = "Hoy estamos celebrando, es tu aniversario.<br>¡Tenemos un mensaje para ti! ";
                notification.image = this.logo;
                break;
            case 'birthday.reward':
                notification.title = "Hoy estamos celebrando.<br>¡Feliz cumpleaños a ti! ";
                notification.image = this.logo;
                break;
        }

        this.notifications.unshift(notification);
        // "event" receives the new post information
        // Calculate the unread count
        this._calculateUnreadCount();

        // Mark for check
        this._changeDetectorRef.markForCheck();

    }
}
