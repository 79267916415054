import { BehaviorSubject, Observable, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Setting, Notification } from './setting.type';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  private _settings: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _settingsCustomer: BehaviorSubject<Setting | null> = new BehaviorSubject(null);
  private _settingsComplete: BehaviorSubject<Setting | null> = new BehaviorSubject(null);
  private _notifications: BehaviorSubject<Notification[] | null> = new BehaviorSubject(null);
  private baseUrl: string;
  private _settingsNotify: BehaviorSubject<Setting | null> = new BehaviorSubject(null);

  constructor(
    private httpClient: HttpClient
  ) {
    this.baseUrl = `${environment.apiURL}/setting`;
  }

  get setting$(): Observable<{ color: string; logo: string; word_coin: string; coin_avatar:string; title:string }> {
    return this._settings.asObservable();
  }

  get settingCustomer$(): Observable<Setting> {
    return this._settingsCustomer.asObservable();
  }

  get settingComplete$(): Observable<Setting> {
    return this._settingsComplete.asObservable();
  }

  get notifications$(): Observable<Notification[]> {
    return this._notifications.asObservable();
  }

  list(): Observable<Setting> {
    const endpoint: string =  `${environment.apiURL}/settings/setup`;
    return this.httpClient.get<Setting>(endpoint)
    .pipe(tap((res) => {
      this._settingsComplete.next(res);
      this._notifications.next(res.notifications);
    }));
  }

  get(): Observable<{ color: string; url_logo: string; word_coin: string; coin_avatar:string; title:string }> {
    const endpoint: string = environment.apiURL + '/settings/login';
    return this.httpClient.get<{ color: string; url_logo: string; word_coin: string; coin_avatar:string; title:string }>(endpoint)
    .pipe(tap((res) => {
      this._settings.next(res);
    }));
  }

  update(setting: Setting, file: File = null) {
    const endpoint: string = this.baseUrl + '/update';

    const formData = new FormData();
    formData.append('points_name', setting.points_name);
    formData.append('points_max', setting.points_max.toString());
    formData.append('points_min', setting.points_min.toString());
    formData.append('primary_color', setting.primary_color);
    formData.append('limitGift', setting.limitGift ? '1' : '0');
    formData.append('limitPoints', setting.limitPoints ? '1' : '0');
    formData.append('limitMatchs',setting.limitMatchs?'1':'0');
    formData.append('pointMaxDay', setting.pointMaxDay.toString());
    formData.append('max_number_same_partner',setting.max_number_same_partner.toString());
    formData.append('max_points_gift_day', setting.max_points_gift_day.toString());
    formData.append('max_match_day',setting.max_match_day.toString());
    formData.append('period', setting.period.toString());
    formData.append('period_id', setting.period_id.toString());
    formData.append('sendedSame', setting.sendedSame.toString());
    formData.append('business_name', setting.business_name);

    formData.append('id', setting.id.toString());

    if(file) formData.append('img', file);

    return this.httpClient.post(endpoint, formData);
  }

  updateNotifications(data: any) {
    const endpoint: string = this.baseUrl + '/update-notifications';
    return this.httpClient.post(endpoint, data);
  }

  updateNotifyItem(data: any) {
    const endpoint: string = this.baseUrl + '/update-notifycation-item';
    return this.httpClient.post(endpoint, data);
  }

  updateSetup(data: any, file: File = null) {
    const endpoint: string =  `${environment.apiURL}/settings/setup`;

    const formData = new FormData();
    formData.append('name', data.name_coins);
    formData.append('max', data.max_coins);
    formData.append('min', data.min_coins);
    formData.append('type', data.type);
    if(file) formData.append('img', file);



    return this.httpClient.post(endpoint, formData);
  }

  getSettingCustomer(): Observable<Setting> {
    const endpoint: string = `${environment.apiURL}/settings/login`;
    return this.httpClient.get<Setting>(endpoint)
    .pipe(tap((res) => {
      this._settingsCustomer.next(res);
    }));
  }

  updatecustumer(data: any, file: File = null) {
    const endpoint: string =  `${environment.apiURL}/settings/login`;

    const formData = new FormData();
    formData.append('business_name', data.title);
    formData.append('color', data.color);
    formData.append('type', data.type);
    if(file) formData.append('img', file);



    return this.httpClient.post(endpoint, formData);
  }

  getsecurity(): Observable<Setting>{
    const endpoint: string = `${environment.apiURL}/settings/security`;
    return this.httpClient.get<Setting>(endpoint)
    .pipe(tap((res) => {
      this._settingsCustomer.next(res);
    }));
  }

  updatesecurity(data: any) {
    const endpoint: string = `${environment.apiURL}/settings/security`;
    return this.httpClient.put(endpoint, data);
  }

  getnotify(): Observable<any>{
    const endpoint = `${environment.apiURL}/settings/notification`;
    return this.httpClient.get<any>(endpoint)
    .pipe(tap((res) => {
      this._settingsNotify.next(res);
    }));
  }

  updatenotify(data: any, id: number) {
    const endpoint = `${environment.apiURL}/settings/notification/`+id;
    return this.httpClient.put(endpoint, data);
  }


}
